import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { logger } from '@commercetools-frontend/application-shell-connectors';
import type { ExportMessage, ImportMessage, SocketMessage, PricePromiseMessage } from '../types';
import { SocketMessageType } from '../types';
import { useSettings } from './SettingsProvider';
import { s3ObjectUrl, triggerFileDownload } from '../utils';
import { useS3Client } from '../hooks';
import { useBusinessToolsDispatch } from '../store/businessToolsStore';
import { addExportMessage, addImportMessage, setExportProgress, setImportProgress } from '../slices/importExportSlice';
import { finishUpdateInProgress } from '../slices/pricePromiseSlice';
import { useGlobalNotification } from './GlobalNotification';

export interface SocketData {
  connectionId?: string;
}

const SocketContext = createContext<SocketData>(null!);
export const useSocket = () => useContext(SocketContext);

export function SocketProvider({ children }: { children: ReactNode }) {
  const { socketUrl, exportReportsBucketName } = useSettings();
  const s3Client = useS3Client();
  const [connectionId, setConnectionId] = useState<string | undefined>();
  const dispatch = useBusinessToolsDispatch();
  const { error: globalError, success: globalSuccess } = useGlobalNotification();

  const onExport = useCallback(
    async ({ error, exportFileName, exportType, type }: ExportMessage) => {
      const downloadUrl = await s3ObjectUrl(
        s3Client,
        exportReportsBucketName,
        `${exportType}/archived/${exportFileName}`,
      );

      if (error) {
        globalError(`An error occured during export (${exportType}).`);

        logger.error(error);
      } else {
        globalSuccess(`Preparing ${exportType} export file for download... Please wait.`);

        dispatch(
          addExportMessage({
            type,
            exportFileName,
            exportType,
            downloadUrl,
          }),
        );

        triggerFileDownload(downloadUrl);
      }

      dispatch(
        setExportProgress({
          type: exportType,
          value: false,
        }),
      );
    },
    [exportReportsBucketName, s3Client, dispatch, globalError, globalSuccess],
  );

  const onImport = useCallback(
    ({ error, importFileName, type, importType }: ImportMessage) => {
      if (error && Array.isArray(error) && error.length > 0) {
        globalError(`An error occured during import (${importFileName}).`);

        logger.error(error);
      } else {
        globalSuccess(`Import successful. File: ${importFileName}`);

        dispatch(
          addImportMessage({
            type,
            importType,
            importFileName,
          }),
        );
      }

      dispatch(
        setImportProgress({
          type: importType,
          value: false,
        }),
      );
    },
    [dispatch, globalError, globalSuccess],
  );

  const onPricePromise = useCallback(
    ({ pricePromiseKey, status }: PricePromiseMessage) => {
      if (status === 'SUCCESS') {
        globalSuccess(`Price promise request ${pricePromiseKey} processed successfully.`);
      }

      if (status === 'ERROR') {
        globalError(`An error occured during price promise request ${pricePromiseKey}.`);
      }

      if (status === 'PENDING_DECISION') {
        globalSuccess(`Price promise request ${pricePromiseKey} processed successfully.`);
      }

      dispatch(finishUpdateInProgress());
    },
    [dispatch, globalError, globalSuccess],
  );

  const onMessage = useCallback(
    async (event: MessageEvent) => {
      const data: SocketMessage = JSON.parse(event.data);
      switch (data.type) {
        case SocketMessageType.CONNECTION_ID: {
          setConnectionId(data.connectionId);
          break;
        }

        case SocketMessageType.EXPORT: {
          await onExport(data);
          break;
        }

        case SocketMessageType.IMPORT: {
          onImport(data);
          break;
        }

        case SocketMessageType.PRICE_PROMISE: {
          onPricePromise(data);
          break;
        }

        default:
          throw new Error(`Unknown message type: ${data.type}`);
      }
    },
    [onExport, onImport, onPricePromise],
  );

  useEffect(() => {
    const connectionTimeout = 3600 * 1000; // 60 minutes, needed for long-running imports/exports
    const socket = new ReconnectingWebSocket(socketUrl, [], { connectionTimeout });

    socket.addEventListener('open', () => {
      logger.info('Socket connection opened.');
      socket.send(JSON.stringify({ type: SocketMessageType.CONNECTION_ID }));
    });

    socket.addEventListener('message', (event) => {
      onMessage(event);
    });

    socket.addEventListener('error', (event) => {
      logger.error(`Socket error: ${event}`);
    });

    return () => {
      socket.close();
    };
  }, [onMessage, socketUrl]);

  return (
    <SocketContext.Provider value={useMemo(() => ({ connectionId }), [connectionId])}>
      {children}
    </SocketContext.Provider>
  );
}
