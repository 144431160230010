import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import importExportSlice from '../slices/importExportSlice';
import appConfigSlice from '../slices/appConfigSlice';
import pricePromiseSlice from '../slices/pricePromiseSlice';

export const businessToolsStore = configureStore({
  reducer: {
    config: appConfigSlice,
    importExport: importExportSlice,
    pricePromise: pricePromiseSlice,
  },
});

export type BusinessToolsState = ReturnType<typeof businessToolsStore.getState>;
export type BusinessToolsDispatch = typeof businessToolsStore.dispatch;

export const useBusinessToolsDispatch = () => useDispatch<BusinessToolsDispatch>();
export const useBusinessToolsSelector: TypedUseSelectorHook<BusinessToolsState> = useSelector;
