/* eslint-disable no-param-reassign */
import type { PricePromiseStatus } from '@ct/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ImportExportState {
  approvalUpdateInProgress: boolean;
  rejectUpdateInProgress: boolean;
  pendingUpdateInProgress: boolean;
  updateInProgress: boolean;
  shouldRefetchPricePromise: boolean;
}

const initialState: ImportExportState = {
  approvalUpdateInProgress: false,
  rejectUpdateInProgress: false,
  pendingUpdateInProgress: false,
  updateInProgress: false,
  shouldRefetchPricePromise: false,
};

export const pricePromiseSlice = createSlice({
  name: 'pricePromise',
  initialState,
  reducers: {
    setUpdateInProgress: (state, action: PayloadAction<{ type: PricePromiseStatus; value: boolean }>) => {
      if (action.payload.type === 'APPROVED') {
        state.approvalUpdateInProgress = action.payload.value;
      }
      if (action.payload.type === 'REJECTED') {
        state.rejectUpdateInProgress = action.payload.value;
      }
      if (action.payload.type === 'PENDING_DECISION') {
        state.pendingUpdateInProgress = action.payload.value;
      }
      state.updateInProgress = action.payload.value;
    },

    finishUpdateInProgress: (state) => {
      state.approvalUpdateInProgress = false;
      state.rejectUpdateInProgress = false;
      state.pendingUpdateInProgress = false;
      state.updateInProgress = false;
      state.shouldRefetchPricePromise = true;
    },

    setShouldRefetchPricePromise: (state, action: PayloadAction<boolean>) => {
      state.shouldRefetchPricePromise = action.payload;
    },
  },
});

export const { setUpdateInProgress, finishUpdateInProgress, setShouldRefetchPricePromise } = pricePromiseSlice.actions;

export default pricePromiseSlice.reducer;
